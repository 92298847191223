<template>
    <div class="card">
        <div class="card-body">
            <LoadingTableForm
                :cols="2"
                :rows="5"
                class="table-bordered bg-white mb-0"
                v-if="loading" />

            <table
                class="table table-bordered table-nowrap table-form mb-0"
                v-else>
                <thead>
                    <tr>
                        <th
                            colspan="2"
                            class="bg-dark bg-gradient text-white text-start">
                            {{ $t($route.params.asset) }} {{ $t('add') }}/{{
                                $t('subtract')
                            }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th class="bg-light bg-soft" style="width: 40%">
                            {{ $t('username') }}
                        </th>
                        <td style="width: 60%">
                            <div class="mt-2">
                                <a href="javascript:void(0)"
                                    ><i
                                        :class="`mdi mdi-numeric-${pl.player.level}-box fs-5 level-${pl.player.level} me-1`">
                                    </i>
                                    {{ pl.username }}</a
                                >
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="bg-light bg-soft" style="width: 40%">
                            {{ $t('holding') }} {{ $t($route.params.asset) }}
                        </th>
                        <td style="width: 60%">
                            <div class="mt-2">
                                {{
                                    numberString(
                                        pl.player.assets[$route.params.asset]
                                    )
                                }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="bg-light bg-soft" style="width: 40%">
                            {{ $t('points') }}
                        </th>
                        <td style="width: 60%">
                            <VueNumberFormat
                                class="form-control text-end rounded-end"
                                :class="{
                                    'is-invalid':
                                        submitted && v$.pl.amount.$error,
                                }"
                                v-model:value="pl.amount"
                                :options="{
                                    precision: 0,
                                    prefix: '',
                                    suffix: '',
                                    thousand: ',',
                                    acceptNegative: false,
                                    isInteger: true,
                                }"></VueNumberFormat>
                            <!-- <div
                                            v-for="(item, index) in v$.pl.amount.$errors"
                                            :key="index"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">
                                                {{$t(item.$message)}}
                                            </span>
                                        </div> -->
                        </td>
                    </tr>
                    <tr>
                        <th class="bg-light bg-soft" style="width: 40%">
                            {{ $t('note') }}
                        </th>
                        <td style="width: 60%">
                            <textarea
                                v-model="pl.note"
                                class="form-control"
                                :class="{
                                    'is-invalid':
                                        submitted && v$.pl.note.$error,
                                }"
                                rows="2"
                                style="resize: none"></textarea>
                            <!-- <div
                                            v-for="(item, index) in v$.pl.note.$errors"
                                            :key="index"
                                            class="invalid-feedback"
                                        >
                                            <span v-if="item.$message">
                                                {{$t(item.$message)}}
                                            </span>
                                        </div> -->
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex justify-content-evenly gap-3 mt-3">
                <button
                    type="button"
                    class="btn btn-success flex-grow-1"
                    :class="{ disabled: loading }"
                    @click="submitForm('add')">
                    {{ $t('add') }}
                </button>
                <button
                    type="button"
                    class="btn btn-danger flex-grow-1"
                    :class="{ disabled: loading }"
                    @click="submitForm('subtract')">
                    {{ $t('subtract') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

import {
    required,
    minValue,
    maxValue,
    between,
    helpers,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapActions } from 'vuex';
import number from '../../../mixins/number';
import LoadingTableForm from '@/components/loading-table-form';
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        LoadingTableForm,
    },
    mixins: [number],
    data() {
        return {
            title: 'Player Form',
            loading: false,
            submitted: false,
            _id: this.$route.params.id,
            pl: {
                username: null,
                name: null,
                nickname: null,
                player: {
                    level: null,
                    assets: {
                        balance: 0,
                        points: 0,
                    },
                },
                amount: 0,
                note: null,
            },
        };
    },
    validations() {
        return {
            pl: {
                amount: {
                    required: helpers.withMessage('required', required),
                    minValueValue: helpers.withMessage('required', minValue(1)),
                    maxValueValue: helpers.withMessage(
                        'the maximum value is 9,999,999,999',
                        maxValue(9999999999)
                    ),
                },
                note: {
                    required: helpers.withMessage('required', required),
                },
            },
        };
    },
    methods: {
        ...mapActions('player', {
            playerUpdateAssets: 'updateAssets',
            playerView: 'view',
        }),
        async submitForm(action) {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return;
            } else {
                const confirmed = await Swal.fire({
                    title: `${this.$t('are you sure')}?`,
                    html: `<table class="table table-bordered table-sm ">
                        <tbody>
                            <tr>
                                <th class="text-lowercase">${this.$t(
                                    'action'
                                )}</th>
                                <td>${this.$t(action)} ${this.numberString(
                        this.pl.amount
                    )} ${this.$t(this.$route.params.asset)}</td>
                            </tr>
                            <tr>
                                <th class="text-lowercase">${this.$t(
                                    'username'
                                )}</th>
                                <td>${this.pl.username}</td>
                            </tr>
                        </tbody>
                    </table>`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#f1b44c',
                    cancelButtonColor: '#74788d',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('confirm'),
                }).then(async (result) => {
                    return result.value;
                });

                if (confirmed) {
                    var fd = new FormData();
                    fd.append('amount', this.pl.amount);
                    fd.append('note', this.pl.note);

                    const res = await this.playerUpdateAssets({
                        id: this._id,
                        asset: this.$route.params.asset,
                        action: action,
                        form_data: fd,
                    });

                    if (res) {
                        Swal.fire({
                            html: this.$t(res.data.message.toLowerCase()),
                            icon: res.success ? 'success' : 'error',
                            timer: res.success ? 2000 : null,
                        });
                        this.getDetails();
                        this.reset();
                    }
                }
            }
        },
        async getDetails() {
            if (!this._id) {
                return;
            }
            this.loading = true;
            this.submitted = false;
            const data = await this.playerView(this._id);
            /*  */
            this.pl.username = data.username;
            this.pl.name = data.name;
            this.pl.nickname = data.nickname;
            this.pl.player.level = data.player.level;
            this.pl.player.assets.balance = data.player.assets.balance;
            this.pl.player.assets.points = data.player.assets.points;

            this.loading = false;
        },
        async reset() {
            this.submitted = false;
            this.pl.amount = 0;
            this.pl.note = data.note;
        },
    },
    mounted() {
        this.getDetails();
    },
};
</script>
